<template>
  <div>
    <CButton @click="getSite()">getSite</CButton>
    <CButton @click="postSite()">postSite</CButton>
    <CButton @click="putSite()">putSite</CButton>
    <CButton @click="putSiteStatus()">putSiteStatus</CButton>
    <CButton @click="deleteSite()">deleteSite</CButton>
    <pre><code><strong>getSiteById:</strong> {{site}}</code></pre>
    <pre><code><strong>getSiteAll:</strong>{{siteList}}</code></pre>

    <br>

    <CButton @click="getAmenity()">getAmenity</CButton>

  </div>
</template>

<script>
export default {
  data() {
    return {
      site: {},
      siteList: [],
      amenityList: [],
    };
  },

  computed: {
    // siteId() {
    //   return this.$route.params.siteId;
    // },
  },

  methods: {

    getSite() {
      this.reqGetSite(a => this.site = a);
      this.reqGetSiteAll(a => this.siteList = a);
    },

    postSite() {
      const body = {
        code: "aaaaa",
        name: "aaaaa",
        url: "https://yado.liberty-resort.net/aaaaa",
      };
      this.reqPostSite(body);
    },

    putSite() {
      const body = [
        {
          code: "official5",
          name: "おふぃしゃる5",
          description: "おふぃしゃるサイト掲載先5",
          url: "https://yado.liberty-resort.net/official5",
          rssLinkFormat: "https://yado.liberty-resort.net/{facilityCode}/official5?planCode={planCode}",
          dispOrder: 5,
          hasUsePoint: false,
          status: 11,
          language: "ja"
        }
      ];
      this.reqPutSite(body);
    },

    putSiteStatus() {
      const body = { status: 200 };
      const callback = a => a;
      this.reqPutSiteStatus(this.$route.params.siteId, body, callback);
    },

    deleteSite() {
      this.reqDeleteSite();
    },

    getAmenity() {
      this.reqGetAmenityAll(a => this.amenityList = a);
    },

  },
}
</script>
